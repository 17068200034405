import { http } from "@/commons/utils/axios-instances"
import type { CountryCodeSchemaType } from "@/commons/utils/countries"
import type { CheckoutSchemaType } from "../typings/organization"
import type { CartSchemaType, PaymentMethodSchemaType } from "./../typings/cart"

export const fetchCart = (
  params: { countryCode?: CountryCodeSchemaType; paymentType?: PaymentMethodSchemaType } = {},
) => http.get<CartSchemaType>("/me/cart", { params }).then((res) => res.data)

export const callCartAddVintage = (
  data: { vintageId: number; credits: number; taxOffset: boolean },
  params: { countryCode?: CountryCodeSchemaType; paymentType?: PaymentMethodSchemaType } = {},
) => http.post<CartSchemaType>("/me/cart/vintages", data, { params }).then((res) => res.data)

export const callCartRemoveVintage = (
  data: { vintageId: number },
  params: { countryCode?: CountryCodeSchemaType; paymentType?: PaymentMethodSchemaType } = {},
) =>
  http
    .delete<CartSchemaType>("/me/cart/vintages", {
      data,
      params,
    })
    .then((res) => res.data)

export const callCartAddReport = (
  data: { reportId: number },
  params: { countryCode?: CountryCodeSchemaType; paymentType?: PaymentMethodSchemaType } = {},
) => http.post<CartSchemaType>("/me/cart/reports", data, { params }).then((res) => res.data)

export const callCartRemoveReport = (
  data: { reportId: number },
  params: { countryCode?: CountryCodeSchemaType; paymentType?: PaymentMethodSchemaType } = {},
) =>
  http
    .delete<CartSchemaType>("/me/cart/reports", {
      data,
      params,
    })
    .then((res) => res.data)

export const callCartCheckout = (data: CheckoutSchemaType) =>
  http.post("/me/cart/checkout", data).then((res) => res.data)

export const callCartCheckoutStripe = (data: CheckoutSchemaType) =>
  http.post<{ redirectionUrl: string }>("/me/cart/checkout/stripe", data).then((res) => res.data)
